import { InitThemeColor } from '@/constants/base'
import { envUtils } from '@nbit/utils'

const { EnvTypesEnum } = envUtils
/** 获取是否是 NodeJs 服务器环境 */
export const envIsServer = import.meta.env.SSR
/** 获取是否是客户端环境 */
export const envIsClient = !envIsServer
/** 是否是开发环境 */
export const envIsDev = import.meta.env.VITE_NEWBIT_ENV === EnvTypesEnum.development
/** 是否是 sg dev 环境 */
export const envIsSGDev = import.meta.env.VITE_NEWBIT_ENV === EnvTypesEnum.dev
/** 是否是生产环境 */
export const envIsProd = import.meta.env.VITE_NEWBIT_ENV === EnvTypesEnum.production

export const envIsBuild = !envIsDev

export const baseUrl = envIsClient
  ? import.meta.env.VITE_MARKCOIN_BASE_URL
  : import.meta.env.VITE_MARKCOIN_SERVER_BASE_URL
export const otcUrl = import.meta.env.VITE_MARKCOIN_WS
export const swapUrl = import.meta.env.VITE_MARKCOIN_WS

// 合约 ws
export const wsFuturesUrl = import.meta.env.VITE_MARKCOIN_WS_CONTRACT
// 现货 ws
export const wsUrl = import.meta.env.VITE_MARKCOIN_WS

export const port = import.meta.env.VITE_PORT
// git  最近的 id
export const gitCommitId = import.meta.env.VITE_GIT_COMMIT_ID

/** AWS S3 config */
export const awsS3Config = {
  region: import.meta.env.VITE_AWS_S3_REGION,
  accessKeyId: import.meta.env.VITE_AWS_S3_ACCESS_KEY_ID,
  secretAccessKey: import.meta.env.VITE_AWS_S3_SECRET_ACCESS_KEY,
  bucket: import.meta.env.VITE_AWS_S3_BUCKET,
}
export const newbitEnv = import.meta.env.VITE_NEWBIT_ENV

export const businessId = import.meta.env.VITE_MARKCOIN_BUSINESS_ID

// monkey business user
export const monkeyBid = '1'
export const isMonkey = businessId === monkeyBid

/** 区分公共版和商户版 */
export const systemThemeColor =
  InitThemeColor[import.meta.env.VITE_MARKCOIN_DEFAULT_THEME] ||
  (isMonkey ? InitThemeColor.common : InitThemeColor.merchant)

export const H5Url = import.meta.env.VITE_MARKCOIN_H5_URL
export const WebUrl = import.meta.env.VITE_MARKCOIN_WEB_URL
export const WebKey = import.meta.env.VITE_MARKCOIN_WEB_ACCESS_KEY_ID
